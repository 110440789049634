.competition-header {
  background-image: url("../../assests/trading-4.png");
  width: 100%;
  height: 30vh;
  background-position: center;
  background-size: cover;
  position: relative;
}

.competition-header .transperant-bg {
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 30vh;
}

.competition-header h5 {
  background-color: #11304e69;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  color: white;
}

.competition-header span {
  color: var(--main-color);
}

.competition {
  background-color: #f7f7f7;
  margin: auto;
  padding: auto;
  padding-bottom: 30px;
}

.question-card {
  cursor: pointer;
  transition: all 0.5s ease;
  margin: 30px 0;
  padding: 20px;

  border-radius: 0;

}

.date-comp {
  position: absolute;
  left: 0;
  bottom: 0;
  color: black;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  transition: all 0.5s ease;
  padding: 10px;
}

.question-card:hover {
  /* box-shadow: 5px 0 10px var(--main-color); */
    border-top: 4px solid var(--main-color);
}

.question-card h6 {
  font-size: 18px !important;
  font-weight: 600;
}

.link-question {
  text-decoration: none;
  color: black;
}

.link-question:hover {
  color: black;
}

.competition h4 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 30px 0 25px 0;
  text-align: center;
  margin: 0 auto 20px auto;
  color: var(--second-color);
}

.competition h4::after {
  content: "";
  display: block;
  width: 140px;
  height: 4px;
  background-color: var(--second-color);
  margin: 10px auto 10px auto;
}
