.recommendation h4 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0 10px 0;
  text-align: center;
  margin: 0 auto 30px auto;
  color: var(--second-color);
}

.recommendation h4::after {
  content: "";
  display: block;
  width: 260px;
  height: 4px;
  background-color: var(--second-color);
  margin: 15px auto 10px auto;
}

.recommendation {
  background-color: #f7f7f7;
}

.recom-card p {
  color: black !important;
}

.recommendation .carousel-control-next-icon,
.recommendation .carousel-control-prev-icon {
  background-color: black !important;
  margin-bottom: 60px;
}

.recommendation .carousel-control-next,
.recommendation .carousel-control-prev {
  width: auto;
}

.recom-card-parent {
  width: 90%;
  margin: 20px auto 80px auto;
}

.recommendation .carousel-indicators {
  display: none;
}

.recom-card thead {
  background-color: var(--main-color);
}

.fall-line {
  color: red;
  font-size: 30px;
  padding-right: 10px;
}

.rise-line {
  color: green;
  font-size: 30px;
  padding-right: 10px;
}

.table-responsive {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .recommendation h4 {
    font-size: 38px;
    line-height: 54px;
    font-weight: 600;
    padding: 25px 0;
    text-align: center;
    margin: 0 auto 30px auto;
  }

  .recommendation .carousel-control-next-icon,
  .recommendation .carousel-control-prev-icon {
    display: none;
  }

  .recommendation .carousel-control-next,
  .recommendation .carousel-control-prev {
    display: none;
  }
}

.recom-card {
  background-color: var(--second-color);
  transition: all 0.5s ease;
  height:262px;
}

.recom-card p {
  color: white !important;
  margin: 8px 0;
    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.recom-card span {
  color: var(--main-color);
  margin: 0 5px;
}
