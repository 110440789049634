.headbar {
  background-color: var(--second-color);
}

.marq {
  height: 40px;
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;
}

.headbar div {
  margin-left: 100px;
}

.headbar p {
  margin: 0 100px;
}

.overlay::before,
.overlay::after {
  background: none !important;
}

@keyframes logoAnim {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(50px);
  }
}

.headbar span {
  margin: 0 10px;
}

.headbar {
  background: var(--second-color);
  color: white;
}

.headbar h6 {
  font-size: 15px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .headbar h6 {
    font-size: 12px;
    font-weight: bold;
  }
  @keyframes logoAnim {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(30px);
    }
  }
}
