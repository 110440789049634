.services{
    background-color: #f7f7f7;
        padding-bottom: 60px;
        display: flex;
}

.services h4{
font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;
  text-align: center;
  margin: 10px auto 30px auto;
  color: var(--second-color);
}

.services h4::after{
    content: "";
  display: block;
  width: 160px;
  height: 4px;
  background-color: var(--second-color);
  margin: 10px  auto 8px  auto;
}

.services-icon2{
     font-size:120px !important;
    margin: 0 15px ;
    color: var(--main-color);
}

.services-icon{
     font-size:80px !important;
    margin: 0 15px ;
      color: var(--main-color);
}

.services-icon3{
     font-size:60px !important;
    margin: 0 15px ;
      color: var(--main-color);
}

.loading{
  padding: 30px 0;
}

.services-card{
    height: 90% ;
}

.services-card{
 text-overflow: ellipsis;
 overflow:hidden;
}