.logo {
  width: 64px !important;
  height: 60px !important;
  cursor: pointer;
  border-radius: 2px;
}

.logo-link {
  width: 10%;
}

.navbar-collapse {
  justify-content: center;
}

.links {
  text-decoration: none;
  color: white;

  margin: 0 8px;
  padding: 15px 0;
}

.links:hover {
  color: var(--main-color);
  text-decoration: none;
  border-bottom: 3px solid var(--main-color);
}

.login-link {
  color: #c6c3c3;
}

.login-link button {
  padding: 5px 10px 10px 10px;
  border-radius: 5px;
  background-color: white;
  transition: all 0.5s ease;
}

.login-link button:hover {
  padding: 5px 10px 10px 10px;
  border-radius: 5px;

  background-color: var(--main-color);
  color: white;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--main-color) !important;
}

.navbar-trust {
  position: sticky;
  top: -1px;
  z-index: 9999;
}

.topbarImg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--main-color);
  margin-left: 20px;
}

.dropdown-user {
  background-color: transparent !important;
  padding: 0 !important;
  border: none !important;
}

.dropdown-user:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-user:focus .topbarImg {
  border: 2px solid var(--main-color);
}

.dropdown-toggle::after {
  color: var(--main-color) !important;
  vertical-align: top;
  left: 0;
  bottom: 45%;
  position: absolute;
}

.dropdown-items:focus {
  background-color: transparent !important;
}
.dropdown-menu {
  right: 0;
}

.logout-btn {
  width: 100%;
  border: none;
  padding: 8px 0;
  background: var(--main-color);
  color: white;
  border-radius: 5px;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .links:hover {
    color: var(--main-color);
    text-decoration: none;
    border: none;
  }

  .links-route-btns {
    display: flex;
    align-items: center;

    flex-direction: row;
    margin: 20px 0;
  }

  .links-route div {
    margin: 10px 0;
  }
}
