.contact-comp {
  background-image: url("../../assests/bg-4.jpg");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-attachment: fixed;
}

.freelogin-sec textarea {
  margin: 12px;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  padding: 10px 40px;
  outline: none;
  border: none;
  transition: all 0.5s ease;
}

.freelogin-sec textarea:focus {
  outline: none;
  box-shadow: 0 0 10px var(--main-color);
}

.contact-comp h6 {
  color: white;
  font-size: 35px;
  font-weight: 700;
  margin: 25px 0;
}

.contact-comp h4 {
  color: var(--main-color);
  font-size: 45px;
  font-weight: bold;
}

.problems {
  background-color: #e1dfdf26 !important;
}

.problems button {
  background-color: var(--second-color);
  padding: 10px;
  color: white;
  border: 1px solid var(--second-color);
  border-radius: 5px;
  border-radius: 5px;
  transition: all 0.5s ease;
  font-weight: 600;
}

.problems button:hover {
  background-color: transparent;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

@media screen and (max-width: 768px) {
  .contact-comp h4 {
    font-size: 38px;
    font-weight: 600;
  }

  .contact-comp h6 {
    color: white;
    font-size: 32px;
    font-weight: 600;
  }
}
