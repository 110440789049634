.performance-rate-header{
       background-image: url("../../assests/trading-4.png");
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: cover;
    position: relative;
}

.performance-rate-header .transperant-bg{
    background-color: rgba(0, 0, 0, 0.67);
    width: 100%;
    height: 30vh;
}

.performance-rate-header h5{
   background-color: #11304e69;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align:center;
    color: white;
}

.performance-rate-header span{
    color: var(--main-color);
}

.performance-rate{
     background-color: #f7f7f7;
     padding: 30px 0;
}

.card-text{
    color: var(--main-color);
    font-weight: bold;
}

.title-num{
    color: var(--main-color);
}

.text-num{
    color: white !important;
}

.performance-rate img{
    width: 100px;
    height: 100px;
    margin: 10px;
}

.performance-rate button{
    background-color:var(--second-color);
    outline: none;
    border: 1px solid var(--main-color);
    padding: 10px 8px;
    border-radius: 5px;
font-weight: bold;
margin: 10px 0;
transition: all 0.5s ease;
color: white;
}

.performance-rate button:hover{
    background-color: var(--main-color);
    border: 1px solid  var(--main-color);
    color: white;
}

.perf-card{
    background-color :var(--second-color) !important;
}



.performance-rate h4{
font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;
  text-align: center;
  margin: auto auto 30px auto;
  color: var(--second-color);
}

.performance-rate h4::after{
    content: "";
  display: block;
  width: 200px;
  height: 4px;
  background-color: var(--second-color);
  margin: 10px  auto 10px  auto;
}