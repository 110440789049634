.advantage-trust{
      /* background-image: url("../../assests/bg-9.jpg"); */
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center center;
  padding:30px 0 60px 0;
    background-color: #f7f7f7;
}

.card{
    padding: 10px;
    min-height:180px;
    margin: 10px 0;
    display: flex;
    align-items:center;
    flex-direction:row;
     /* box-shadow: 0 0 10px var(--main-color); */
     color: var(--second-color);
}

.card .advantage-icon{
    font-size:40px !important;
    margin: 0 15px ;
      color: var(--main-color);
}

.card .advantage-icon2{
    font-size:80px !important;
     margin: 0 15px ;
       color: var(--main-color);
}

.card h6{
    font-size:20px !important ;
    font-weight:bold ;
    padding: 10px 0;
}

.card p{
    color: var(--second-color);
}



.advantage-trust h4{
    font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;
  text-align: center;
  margin: auto;
  color: var(--second-color);
}

.advantage-trust h4::after {
      content: "";
  display: block;
  width: 210px;
  height: 4px;
  background-color: var(--second-color);
  margin: 10px  auto 10px  auto;
}

.private-text{
    color: var(--main-color);
    text-align: center;
    padding: 10px 0 10px 0;
    font-size: 18px;
}

.advantage-card{
    height: 90% ;
}

.advantage-card{
 text-overflow: ellipsis;
 overflow:hidden;
}