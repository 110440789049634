.register-steps {
  background-image: url("../../assests/bg-4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.register-steps h5 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;

  text-align: center;
  margin: 0 auto 50px auto;
  color: white;
}

.register-steps h5::after {
  content: "";
  display: block;
  width: 280px;
  height: 4px;
  background-color: white;
  margin: 15px auto 10px auto;
}

.transperant-bg {
  background-color: #090b28d9;
}

.register-steps h6 {
  color: white;
  font-size: 24px;
  line-height: 40px;
  font-weight: 300px;
  margin: 10px 0;
}

.step-icon {
  font-size: 35px;
  text-align: center;
  color: var(--main-color);
  transition: all 0.5s ease;
}

.icon-div {
  border: 3px dashed var(--main-color);
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.col-steps::before {
  position: absolute;
  right: 87%;
  top: 20%;
  background: url(../../assests/dashed-arrow1.png) no-repeat 0 0;
  content: "";
  width: 174px;
  height: 29px;
  animation: logoAnim 1.5s infinite alternate;
}

.diff::before {
  display: none;
}

@keyframes reg-rotaion {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 768px) {
  .icon-div {
    width: 60px;
    height: 60px;
  }
  .col-steps::before {
    position: absolute;
    right: 87%;
    top: 10%;
    background: url(../../assests/dashed-arrow1.png) no-repeat 0 0;
    content: "";
    width: 54px;
    height: 19px;
    animation: logoAn 1.5s infinite alternate;
  }
  @keyframes logoAn {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(15px);
    }
  }
  .register-steps h6 {
    color: white;
    font-size: 18px;
    line-height: 40px;
    font-weight: 300px;
  }
}
