.back-top-icon {
  position: fixed;
  bottom: 60px;
  right: 40px;
  z-index: 99999;
  cursor: pointer;
  opacity: 1;
  animation: showBackTopBtn 1s;
}

.whatsapp-icon {
  position: fixed;
  bottom: 60px;
  left: 40px;
  z-index: 99999;
  cursor: pointer;
  opacity: 1;
  animation: showBackTopBtn 1s;
  border: none;
  background: transparent;
}

.whatsapp-icon:after {
  content: "";
  border-color: inherit;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 60px;
  left: 46px;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 1.2s;
  animation-name: _pulse;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.hide {
  display: none;
}

.whats-div {
  position: fixed;
  bottom: 80px;
  left: 80px;
  width: 100px;
  height: 100px;
}

@keyframes showBackTopBtn {
  from {
    opacity: 0;
  }
}

@keyframes _pulse {
  0% {
    box-shadow: 0 0 0 0 #25d366;
    opacity: 1;
  }

  100% {
    box-shadow: 0 0 0 15px #25d366;
    opacity: 0;
  }
}

.content {
  width: 300px;
  height: 280px;
  position: fixed;
  bottom: 140px;
  left: 20px;
  border-radius: 10px !important;
  transition: height 0.2s ease-out;
  box-shadow: 5px 10px 10px 5px rgb(0 0 0 / 20%);
  z-index: 9999;
  background-color: #fff;
  opacity: 1;
  animation: showBackTopBtn 1s;
  border-radius: 20px !important;
}

.content img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid white;
}

.head-whats {
  background-color: #097569;
  border-radius: 15px 15px 0 0;
  color: white;
  padding: 5px 0;
}

.library {
  background-color: #f0f0f0;
  border-radius: 0 0 15px 15px;
  padding: 10px 0;
}

.library input {
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #fefefe;
  min-height: 45px;
  transition: box-shadow 0.2s ease-in-out;
}

.library input:focus {
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);
  border: none;
  outline: none;
}

.library .send-ico {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #919191;
  font-size: 22px;
}

.library button,
select {
  border: none !important;
}

.whats-half {
  background-image: url("../../assests/whats-img.png");
  padding: 20px;
  background-size: cover;
  background-repeat: repeat;
  /* max-height: 382px; */
  background-color: #eae6df;
  height: 160px;
  opacity: 0.9;
}

@media screen and (max-width: 768px){
    .whatsapp-icon:after {
  left: 56px;}
}