.pointer {
  min-height: 340px;
  padding:10px 0 40px 0;
}

.pointer h4 {
    font-size: 40px;
    line-height: 54px;
    font-weight: 600;
    padding: 25px 0 10px 0;
    text-align: center;
    margin: 0 auto 20px auto;
    color: var(--second-color);
  }
  
  .pointer h4::after {
    content: "";
    display: block;
    width: 160px;
    height: 4px;
    background-color: var(--second-color);
    margin: 15px auto 10px auto;
  }
  

.pointer-card img {
  width: 100%;
  height: 100%;
}
.pointer-card{
    background-color: #f7f7f7;
    width: 100%;
    margin: auto;
    border: none;
}

.pointer p {
  font-size: 25px;
  font-weight: 600;
  color: var(--second-color);
  padding: 20px 0 0 0;
}

@media screen and (max-width:768px) {
    .pointer p {
        font-size: 22px;
      }
}