:root {
  --main-color: #ff4379;
  --second-color: #112f4e;
  /* --color-num: rgb(1, 129, 65);
  --color-tdawl: #30c2c1;
  --color-tdawl-sec: #2c4251; */
  /* --v-color:#303030; */
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  font-family: "Cairo", sans-serif;
}

.Toastify__toast--error,
.Toastify__toast--success {
  background: white !important;
  color: gray !important;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #e74c3c !important;
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: #07bc0c !important;
}

.Toastify__close-button > svg {
  color: gray !important;
}

.Toastify__close-button > svg:hover {
  color: black !important;
}
.Toastify__toast {
  margin-top: 15px;
  z-index: 999999;
}

@media screen and (max-width: 768px) {
  .container {
    width: 95% !important;
  }
  .Toastify__toast {
    width: 60%;
    margin-top: 35px;
  }
}

.text-dark {
  background-color: var(--second-color) !important;
}

.text-warning {
  background-color: var(--main-color) !important;
}

.pagination {
  padding: 0 !important;
}

.dark-mode .card {
  background-color: #242526;
}

.dark-mode .free-login {
  background-color: #18191a !important;
}

.dark-mode .free-login h4,
.dark-mode .free-login p {
  color: white;
}

.dark-mode .services {
  background-color: #18191a !important;
}

.dark-mode .btn-most {
  color: black;
}

.dark-mode .services h6,
.dark-mode .services p {
  color: white;
}

.dark-mode .services h6,
.dark-mode .services p,
.dark-mode .services h4 {
  color: white !important;
}

.dark-mode .services h4::after {
  background-color: white !important;
}

.dark-mode .advantage-trust {
  background-color: #18191a !important;
}

.dark-mode .advantage-trust h6,
.dark-mode .advantage-trust p {
  color: white;
}

.dark-mode .advantage-trust h6,
.dark-mode .advantage-trust p,
.dark-mode .advantage-trust h4 {
  color: white !important;
}

.dark-mode .advantage-trust h4::after {
  background-color: white !important;
}

.dark-mode .transperant-bg {
  background-color: #18191af3 !important;
}

.dark-mode .news-comp {
  background-color: #18191a !important;
}

.dark-mode .news-comp h4 {
  color: white !important;
}

.dark-mode .news-comp h4::after {
  background-color: white !important;
}

.dark-mode .card-title,
.dark-mode .card-text:last-child {
  color: white !important;
}

.dark-mode .freelogin-sec h5,
.dark-mode .freelogin-sec button {
  background-color: #242526 !important;
}

.dark-mode .freelogin-sec {
  box-shadow: 0 0 5px #242526;
}

.dark-mode .recommendation {
  background-color: #18191a !important;
}

.dark-mode .recommendation .recom-card {
  background-color: #18191a !important;
}

.dark-mode .recommendation h4 {
  color: white !important;
}

.dark-mode .recommendation h4::after {
  background-color: white !important;
}

.dark-mode .navbar-trust {
  background-color: #242526 !important;
  color: white !important;
}

.dark-mode .change-links {
  background-color: white !important;
}

.dark-mode .copy-right {
  background-color: #18191a;
}

.dark-mode .packages,
.dark-mode .performance-rate,
.dark-mode .trust-rate,
.dark-mode .news,
.dark-mode .training,
.dark-mode .competition,
.dark-mode .contact-us {
  background-color: #18191a !important;
}

.dark-mode .package-content h4,
.dark-mode .performance-rate h4,
.dark-mode .trust-rate h4,
.dark-mode .news h4,
.dark-mode .training h3,
.dark-mode .competition h4 ,.dark-mode .pointer h4{
  color: white !important;
}

.dark-mode .package-content h4::after,
.dark-mode .performance-rate h4::after,
.dark-mode .trust-rate h4::after,
.dark-mode .news h4::after,
.dark-mode .training h3::after,
.dark-mode .competition h4::after,.dark-mode .pointer h4::after {
  background-color: white !important;
}

.dark-mode .trust-rate h6 ,.dark-mode .pointer p {
  color: white;
}

.dark-mode .trust-rate label {
  color: white;
}
.dark-mode .trust-rate .form-check-input {
  background-color: #18191a;
}

.dark-mode .trust-rate input {
  background-color: #242526;
  color: white;
}

.dark-mode .news select,
.dark-mode .news button {
  background-color: #242526;
  color: white;
}

.dark-mode .news h5,
.dark-mode .news p {
  color: white;
}

.dark-mode .date-news {
  color: #242526 !important;
}

.dark-mode .card-overlay {
  background-color: rgba(128, 128, 128, 0.551);
}

.dark-mode .training p {
  color: white;
}

.dark-mode .training p {
  color: white;
}

.dark-mode .contact-us h6,
.dark-mode .contact-us .information-icons,
.dark-mode .contact-us .information-icons2 {
  color: white;
}

.dark-mode .competition h3,
.dark-mode .competition h6 {
  color: white;
}

.dark-mode .question-details {
  background-color: #18191a;
}

.dark-mode .question-details .bg-light {
  background-color: #18191a !important;
}

.dark-mode .question-details h1,
.dark-mode .question-details h5 {
  color: white;
}

.dark-mode .question-details .answers {
  background-color: #18191a;
}

.dark-mode .question-details .answers h2,
.dark-mode .question-details .answers h4 {
  color: white !important;
}

.dark-mode .news-details {
  background-color: #18191a;
}

.dark-mode .news-details h3,
.dark-mode .news-details p {
  color: white;
}

.dark-mode .performance-details {
  background-color: #18191a;
}

.dark-mode .performance-details select,
.dark-mode .performance-details button {
  background-color: #242526;
  color: white;
}

.dark-mode .performance-details h4,
.dark-mode .performance-details h3,
.dark-mode .performance-details td {
  color: white;
}

.dark-mode .footer h3,
.dark-mode .footer h5 {
  border: 2px inset gray;
}

.dark-mode .training-card .h-training {
  background-color: #18191a !important;
}

.dark-mode .trust-rate-header h5,
.dark-mode .training-header h5,
.dark-mode .contact-us-header h5,
.dark-mode .competition-header h5,
.dark-mode .about-header h5,
.dark-mode .news-header h5,
.dark-mode .services-header h5,
.dark-mode .performance-rate-header h5,
.dark-mode .packages-header h5,
.dark-mode .performance-details-header h5 {
  background-color: #2425265a;
}

.dark-mode .card-comp .textarea {
  background-color: #242526;
  color: white;
}

.dark-mode .btn-add-comm{
  color: black !important;
}

.dark-mode {
  background-color: #18191a;
}

:root .dark-mode {
  --main-color: rgb(207, 205, 205);
  --second-color: #242526;
  /* --color-num: rgb(1, 129, 65);
  --color-tdawl: #30c2c1;
  --color-tdawl-sec: #2c4251; */
  /* --v-color:#303030; */
}
