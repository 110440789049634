.logo {
  width: 70px !important;
  height: 60px !important;
  cursor: pointer;
  /* border: 1px inset var(--main-color) ; */
  border-radius: 2px;
  /* box-shadow: 0 0 5px var(--main-color); */
}

.logo-link {
  width: 70px !important;
  height: 65px !important;
}

.navbar-collapse {
  justify-content: center;
}

.links {
  text-decoration: none;
  color: white;
  white-space: nowrap;
  margin: 0 8px;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 700;
}

.active-link {
  color: var(--main-color);
  white-space: nowrap;
  margin: 0 12px;
  padding: 10px 0;
  text-decoration: none;
  border-bottom: 3px solid var(--main-color);
  font-size: 14px;
  font-weight: 700;
}

.active-link:hover {
  color: var(--main-color);
  text-decoration: none;
  border-bottom: 3px solid var(--main-color);
}

.links:hover {
  color: var(--main-color);
  text-decoration: none;
  border-bottom: 3px solid var(--main-color);
}

.login-link {
  color: #c6c3c3;
}

.login-link button {
  padding: 5px 10px 10px 10px;
  border-radius: 5px;
  background-color: white;
  transition: all 0.5s ease;
}

.login-link button:hover {
  padding: 5px 10px 10px 10px;
  border-radius: 5px;

  background-color: var(--main-color);
  color: white;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--main-color) !important;
}

.navbar-trust {
  position: sticky;
  top: -1px;
  z-index: 9999;
}

.change-links .links {
  color: var(--second-color) !important ;
}

.change-links .flags select {
  color: var(--second-color);
  /* border: 1px solid var(--main-color) !important; */
}

.change-links .toggle-btn {
  color: var(--second-color);
  cursor: pointer;
  font-size: 40px;

  box-shadow: 0 0 5px  var(--second-color);
  border: 2px solid  var(--second-color);
  border-radius: 5px;
  width: 60px;
}

.btn-login {
  padding: 10px 10px 10px 10px;
  white-space: nowrap;
  border-radius: 5px;
  background-color: white;
  transition: all 0.5s ease;
  border: 1px solid var(--main-color);
  font-size: 14px;
  font-weight: 700;
  color: var(--main-color);
}

.btn-login:hover {
  padding: 10px 10px 10px 10px;
  border-radius: 5px;

  background-color: var(--main-color);
  color: white;
}

.navbar-trust .links {
  padding: 8px;
}

.ff img{
  width: 25px !important;
  height: 25px !important;
  border-radius: 5px;
}

.flags img {
  width: 100%;
  height: 100%;
}

.flags select {
  /* border: 1px solid var(--second-color) !important; */
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 2px;
  cursor: pointer;
  outline: none;
  transition: all 0.5s ease;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.flags option {
  color: black;
  cursor: pointer;
}

.flags select:focus {
  box-shadow: 0 0 10px var(--main-color);
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  color: white !important ;
}

.ReactFlagsSelect-module_selectFlag__2q5gC {
  margin: 0 10px;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  width: 140px !important;
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.ReactFlagsSelect-module_selectBtn__19wW7:focus {
  border: 1px solid var(--main-color);
  box-shadow: 0 0 10px var(--main-color);
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding-bottom: 0 !important;
}

.toggle-btn {
  display: none;
}

@media screen and (max-width: 991px) {
  .links:hover {
    color: var(--main-color);
    text-decoration: none;
    border: none;
  }

  .links-route-btns {
    display: flex;
    align-items: center;

    flex-direction: row;
    margin: 20px 0;
  }

  .links-route div {
    margin: 10px 0;
  }

  .hide {
    display: none;
  }

  .toggle-btn {
    display: block;
  }

  .active-link,
  .active-link:hover,
  .links:hover {
    border-bottom: none;
  }
}

.toggle-btn {
  color: white;
  cursor: pointer;
  font-size: 40px;
}

.toggle-btn {
  box-shadow: 0 0 5px white;
  border: 2px solid white;
  border-radius: 5px;
  width: 60px;
}



details {
  width: 150px;
  cursor: pointer;
  margin: 0 4px -5px 0;
  padding: 0 0 0 10px;
}

summary {
  position: relative;
  width: 96%;
  outline: 0.5px ridge grey;
}


/*
Hides <detail>'s default arrow
*/

details summary {
  list-style: none;
}

details summary::marker {
  display: none;
}

details {
  width: 150px;
  cursor: pointer;
  margin: 0 4px -5px 0;
  padding: 0 0 0 10px;
}

summary {
  position: relative;
  width: 96%;
  outline: 0.5px ridge grey;
}


/*
Hides <detail>'s default arrow
*/

details summary {
  list-style: none;
}

details summary::marker {
  display: none;
}

[open] .opt,
.rad:checked+.opt {
  display: block;
}