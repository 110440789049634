.contact-us-header{
        background-image: url("../../assests/trading-4.png");
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: cover;
    position: relative;
}

.contact-us-header .transperant-bg{
    background-color: rgba(0, 0, 0, 0.67);
    width: 100%;
    height: 30vh;
}

.contact-us-header h5{
    background-color: #11304e69;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align:center;
    color: white;
}

.contact-us-header span{
    color: var(--main-color);
}

.contact-us img{
     width: 180px;
  height: 100px;
  margin: 0 0 30px 0;
}

.contact-us{
    background-color: #f7f7f7;
}

.contact-us h4 , .contact-us h6{
    
font-size:25px;
    font-weight: 600;
    color: var(--second-color);
}

.contact-info h4 {
font-size:50px;
    font-weight: bold;
    color: var(--main-color);
}

.contact-info h6{
font-size:35px;
    font-weight: bold;
    margin: 0 0 25px 0;
     color: var(--main-color);
}

.information-icons{
    color: var(--second-color);
}

.information-icons2{
    color: var(--second-color);
}


@media screen and (max-width: 768px) {
  .contact-info h4 {
    font-size: 36px !important;
  }
}