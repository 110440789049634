.trust-rate-header{
     background-image: url("../../assests/trading-4.png");
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: cover;
    position: relative;
}

.trust-rate-header .transperant-bg{
    background-color: rgba(0, 0, 0, 0.67);
    width: 100%;
    height: 30vh;
}

.trust-rate .other{
  margin: 15px;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  padding: 10px 40px;
  outline: none;
  border: none;
  transition: all 0.5s ease;
  background: rgba(225, 223, 223, 0.59);
}

.trust-rate .other:focus {
  outline: none;
  box-shadow: 0 0 5px var(--main-color);
}

.trust-rate-header h5{
   background-color: #11304e69;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align:center;
    color: white;
}

.trust-rate-header span{
    color: var(--main-color);
}

.trust-rate{
     background-color: #f7f7f7;
}

.trust-rate-content{
    width: 50%;
    margin: auto;
}
.trust-rate-content .input-rate{
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 2px solid var(--main-color);
    padding: 10px 5px;
}

.trust-rate-content .form-check{
    margin: 10px 0;
    display:flex;

}

.trust-rate-content .form-check-input{
    cursor: pointer;
}

.trust-rate-content .form-check-label{
  margin-right: 35px;
}

.trust-rate-content h4{
    font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding:30px 0 25px 0;
  text-align: center;
  margin: 0 auto 20px auto;
  color: var(--second-color);
}

.trust-rate-content h4::after{
    content: "";
  display: block;
  width: 230px;
  height: 4px;
  background-color: var(--second-color);
  margin: 10px  auto 10px  auto;
}

.trust-rate-content button{
    margin: 20px auto 40px auto ;
    border: 1px solid var(--second-color);
    background-color: var(--second-color);
    padding: 8px 30px;
    border-radius: 5px;
    color: white;
    transition: all 0.5s ease;
}

.trust-rate-content button:hover{
    border: 1px solid var(--main-color);
    background-color: var(--main-color);
  color: black;
}

.form-check-input:checked {
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.form-check-input:focus {
  box-shadow: 0 0 5px var(--main-color);
}

.form-check-label {
  cursor: pointer;
}